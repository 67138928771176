import React, { useContext, useEffect, useMemo } from "react";
import { useNavigation } from "react-router-dom";
import configuration from "../services/configuration";
import { LoadScriptContext } from "./LoadScript/LoadScriptProvider";

function gtag(...args: any[]) {
  (window as unknown as IDataLayerWindow).dataLayer.push(...args);
}

interface IDataLayerWindow extends Window {
  dataLayer: { push: (...args: any[]) => void };
}

const Analytics = () => {
  const { scripts } = useContext(LoadScriptContext);
  const isGoogleTagManagerLoaded = useMemo(
    () => !!scripts[configuration.id.googleTagManager],
    [scripts],
  );

  const { location } = useNavigation();

  useEffect(() => {
    if (isGoogleTagManagerLoaded) {
      (window as unknown as IDataLayerWindow).dataLayer =
        (window as unknown as IDataLayerWindow).dataLayer || [];

      gtag("js", new Date());
    }
  }, [isGoogleTagManagerLoaded]);

  useEffect(() => {
    if (isGoogleTagManagerLoaded && location !== undefined) {
      gtag("config", configuration.googleTagManagerApiKey, {
        page_path: location.pathname,
      });
    }
  }, [isGoogleTagManagerLoaded, location]);

  return <></>;
};

export default Analytics;
