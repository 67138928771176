import { IValidationResult } from "../useValidateField";

const isGreaterThan = (
  minValue: number,
  errorMessage = "Please type a number higher than {{minValue}}",
) => {
  return (value: string): IValidationResult => {
    const isValid = Number(value) > minValue;

    return {
      isValid,
      error: [
        isValid ? "" : errorMessage,
        {
          value,
          minValue,
        },
      ],
    };
  };
};

export default isGreaterThan;
