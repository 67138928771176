import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const useWeddingDate = () => {
  const [weddingDate] = useState(
    () => new Date("2022-09-10T19:00:00.000+03:00"),
  );
  const { t } = useTranslation();

  const weddingDateString = useMemo(
    () =>
      `${weddingDate.getDate()} ${t(
        `months.${weddingDate.getMonth()}`,
      )} ${weddingDate.getFullYear()}`,
    [t, weddingDate],
  );

  return { weddingDate, weddingDateString };
};

export default useWeddingDate;
