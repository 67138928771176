import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (i18n.language === "el") {
      navigate("/el");
    } else if (i18n.language === "de") {
      navigate("/de");
    } else {
      navigate("/en");
    }
  }, [i18n.language, navigate]);

  return <></>;
};

export default LanguageSelector;
