import { IValidationResult } from "../useValidateField";

const isInteger = (errorMessage = "Please type a valid number") => (
  value: string,
): IValidationResult => {
  const isValid = /^\d+$/.test(value);

  return {
    isValid,
    error: [
      isValid ? "" : errorMessage,
      {
        value,
      },
    ],
  };
};

export default isInteger;
