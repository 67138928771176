import React, { ReactNode, useContext, useEffect } from "react";
import { LoadScriptContext } from "./LoadScriptProvider";

interface ILoadScriptProps {
  id: string;
  url: string;
  children: ReactNode;
  onLoad?: string;
}

const LoadScript = ({ id, url, onLoad, children }: ILoadScriptProps) => {
  const { updateScriptStatus } = useContext(LoadScriptContext);

  useEffect(() => {
    let script = document.getElementById(`${id}LoadScript`);
    const functionName = `${id}LoadScriptCallback`;
    const onLoadCallback = () => {
      delete (window as any)[functionName];
      updateScriptStatus(id, true);
    };

    if (onLoad) {
      (window as any)[functionName] = onLoadCallback;
    }

    const scriptUrl = onLoad
      ? url.includes("?")
        ? `${url}&${onLoad}=${functionName}`
        : `${url}?${onLoad}=${functionName}`
      : url;

    const injectScript = () => {
      script = document.createElement("script");
      script.setAttribute("id", `${id}LoadScript`);
      script.setAttribute("src", scriptUrl);
      script.setAttribute("async", "true");
      script.setAttribute("defer", "true");
      if (!onLoad) {
        script.onload = onLoadCallback;
      }
      document.body.append(script);

      return script;
    };

    if (script) {
      const currentUrl = script.getAttribute("src");

      if (currentUrl && currentUrl !== scriptUrl) {
        script.remove();
        injectScript();
      }
    } else {
      injectScript();
    }

    return () => {
      delete (window as any)[functionName];
      if (script) {
        script.remove();
      }
    };
  }, [id, url, onLoad, updateScriptStatus]);

  return <>{children}</>;
};

export default LoadScript;
