import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useWeddingDate from "../hooks/useWeddingDate";

interface IDatesDifference {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  totalSeconds: number;
}

const Countdown = () => {
  const { weddingDate } = useWeddingDate();

  const calculateDifference = useCallback(() => {
    const total =
      Math.max(weddingDate.getTime() - new Date().getTime(), 0) / 1000;

    const d = Math.floor(total / (60 * 60 * 24));
    let remaining = total - d * 60 * 60 * 24;

    const h = Math.floor(remaining / (60 * 60));
    remaining = remaining - h * 60 * 60;

    const m = Math.floor(remaining / 60);
    remaining = remaining - m * 60;

    const s = Math.floor(remaining);

    const diff: IDatesDifference = {
      days: d,
      hours: h,
      minutes: m,
      seconds: s,
      totalSeconds: total,
    };

    return diff;
  }, [weddingDate]);

  const [{ days, hours, minutes, seconds }, setDifference] = useState(
    calculateDifference(),
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newDifference = calculateDifference();

      setDifference(newDifference);

      if (newDifference.totalSeconds <= 0) {
        clearInterval(intervalId);
      }
    }, 1000);
  }, [calculateDifference]);

  const { t } = useTranslation();

  return (
    <section className="section-countdown">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-xl">
            <div className="d-flex align-items-center h-100">
              <h2 className="mb-2">{t("We are counting down")}...</h2>
            </div>
          </div>
          <div className="col-lg-7 col-xl">
            <div className="row text-center">
              <div className="col-3">
                <div className="countdown">{days}</div>
                {t("Days")}
              </div>

              <div className="col-3">
                <div className="countdown">{hours}</div>
                {t("Hours")}
              </div>

              <div className="col-3">
                <div className="countdown">{minutes}</div>
                {t("Minutes")}
              </div>

              <div className="col-3">
                <div className="countdown">{seconds}</div>
                {t("Seconds")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Countdown;
