import React from "react";
import { useTranslation } from "react-i18next";

const Invitation = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="section-invitation">
        <h2 className="section-title">{t("Families")}</h2>

        <div className="container">
          <div className="row">
            <div className="col-6 col-md-4 col-lg-3 offset-md-2 offset-lg-3">
              {t("Christos & Maria")}
              <br />
              {t("Pasgiannis")}
            </div>

            <div className="col-6 col-md-4 col-lg-3">
              {t("Ioannis & Kerstin")}
              <br />
              {t("Grigorakis")}
            </div>
          </div>
        </div>
      </section>

      <section className="section-invitation bg-primary">
        <h2 className="section-title">{t("Maid of honour & Best man")}</h2>

        <div className="container">
          {t("Despoina Arapakou")}
          <br />
          {t("Dimitrios Tsitonakis")}
        </div>
      </section>
    </>
  );
};

export default Invitation;
