import React, { ReactNode, useEffect, useRef, useState } from "react";

interface IAnimateOnScrollProps {
  animation: string;
  children: ReactNode;
}

const AnimateOnScroll = ({ animation, children }: IAnimateOnScrollProps) => {
  const [className, setClassName] = useState("invisible");
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = () => {
      if (ref.current) {
        const rectangle = ref.current.getBoundingClientRect();

        if (rectangle.top < window.innerHeight * 0.7) {
          setClassName(`animated ${animation}`);
          window.removeEventListener("scroll", handler);
        }
      }
    };

    window.addEventListener("scroll", handler);

    return () => window.removeEventListener("scroll", handler);
  }, [animation]);

  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  );
};

export default AnimateOnScroll;
