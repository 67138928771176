import React, { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import configuration from "../services/configuration";
import { LoadScriptContext } from "./LoadScript/LoadScriptProvider";

interface IReCaptchaWindow extends Window {
  grecaptcha: {
    render: (
      elementId: string,
      props: {
        sitekey: string;
        theme?: "dark" | "light";
        compact?: "compact" | "normal";
        tabindex?: number;
        hl?: string;
        callback?: (token: string) => void;
        "expired-callback"?: () => void;
        "error-callback"?: () => void;
      },
    ) => number;
    reset: (widgetId?: number) => void;
    getResponse: (widgetId?: number) => string;
  };
}

interface IReCaptchaProps {
  id?: string;
  setChallengePassed: (value: boolean) => void;
}

const ReCaptcha = ({
  id = configuration.id.reCaptcha,
  setChallengePassed,
}: IReCaptchaProps) => {
  const { scripts } = useContext(LoadScriptContext);
  const isReCaptchaLoaded = useMemo(
    () => !!scripts[configuration.id.reCaptcha],
    [scripts],
  );

  const { i18n } = useTranslation();

  useEffect(() => {
    if (isReCaptchaLoaded) {
      const widgetId = ((window as unknown) as IReCaptchaWindow).grecaptcha.render(
        id,
        {
          sitekey: configuration.reCaptchaApiKey,
          hl: i18n.language,
          callback: () => setChallengePassed(true),
          "expired-callback": () => setChallengePassed(false),
        },
      );

      return () => {
        ((window as unknown) as IReCaptchaWindow).grecaptcha.reset(widgetId);
        setChallengePassed(false);
      };
    }
  }, [id, isReCaptchaLoaded, setChallengePassed, i18n.language]);

  return <div id={id} />;
};

export default ReCaptcha;
