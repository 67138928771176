import DynamoDB from "aws-sdk/clients/dynamodb";
import { CognitoIdentityCredentials, config } from "aws-sdk/global";
import configuration from "./configuration";

config.region = configuration.aws.region;
config.credentials = new CognitoIdentityCredentials({
  IdentityPoolId: configuration.aws.identityPoolId,
});

const dynamoDB = new DynamoDB();

export { dynamoDB };
