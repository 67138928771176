/* eslint-disable no-process-env */

const configuration = {
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
  reCaptchaApiKey: process.env.REACT_APP_RECAPTCHA_API_KEY || "",
  googleTagManagerApiKey:
    process.env.REACT_APP_GOOGLE_TAG_MANAGER_API_KEY || "",
  aws: {
    region: process.env.REACT_APP_AWS_REGION || "",
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID || "",
  },
  id: {
    googleTagManager: "googleTagManager",
    googleMaps: "googleMaps",
    reCaptcha: "recaptcha",
  },
};

export default configuration;
