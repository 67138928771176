import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import useWeddingDate from "../hooks/useWeddingDate";

const Welcome = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const handleScrollDownClick = useCallback(() => {
    if (ref.current) {
      window.scrollBy({
        top: ref.current.getBoundingClientRect().bottom,
        behavior: "smooth",
      });
    }
  }, []);

  const { weddingDateString } = useWeddingDate();

  return (
    <section className="welcome-img position-relative" ref={ref}>
      <h1 className="title animated zoomIn">
        {t("Manos")} &amp; {t("Linda")}
      </h1>
      <h2 className="subtitle animated zoomIn delay-1s">{weddingDateString}</h2>

      <button
        className="btn btn-scroll-down d-print-none"
        onClick={handleScrollDownClick}
        aria-hidden="true"
      >
        <span className="icon icon-arrow_downward animated fadeInDown infinite delay-2s" />
      </button>
    </section>
  );
};

export default Welcome;
