import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useContactApi from "../hooks/api/useContactApi";
import useValidateField from "../hooks/validation/useValidateField";
import useValidateForm from "../hooks/validation/useValidateForm";
import isGreaterThan from "../hooks/validation/validators/isGreaterThan";
import isInteger from "../hooks/validation/validators/isInteger";
import isRequired from "../hooks/validation/validators/isRequired";
import isTrue from "../hooks/validation/validators/isTrue";
import CustomFieldError from "./Form/CustomFieldError";
import Form from "./Form/Form";
import FormGroup from "./Form/FormGroup";
import Input from "./Form/Input";
import Select, { ISelectOption } from "./Form/Select";
import TextArea from "./Form/TextArea";
import ReCaptcha from "./ReCaptcha";

const Contact = () => {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const nameValidators = useMemo(() => [isRequired()], []);
  const nameValidation = useValidateField(name, nameValidators);

  const [status, setStatus] = useState("");
  const statusValidators = useMemo(() => [isRequired()], []);
  const statusValidation = useValidateField(status, statusValidators);
  const statusOptions = useMemo<ISelectOption[]>(
    () => [
      { label: t("Yes"), value: true.toString() },
      { label: t("No"), value: false.toString() },
    ],
    [t],
  );

  const showGuests = useMemo(() => status === true.toString(), [status]);
  const [guests, setGuests] = useState("");
  const guestsValidators = useMemo(
    () => (showGuests ? [isRequired(), isInteger(), isGreaterThan(0)] : []),
    [showGuests],
  );
  const guestsValidation = useValidateField(guests, guestsValidators);

  const [message, setMessage] = useState("");

  const [captcha, setCaptcha] = useState(false);
  const captchaValidators = useMemo(() => [isTrue("Are you a robot?")], []);
  const captchaValidation = useValidateField(
    captcha.toString(),
    captchaValidators,
  );

  const formValidators = useMemo(
    () => [
      nameValidation,
      guestsValidation,
      statusValidation,
      captchaValidation,
    ],
    [nameValidation, guestsValidation, statusValidation, captchaValidation],
  );
  const formValidation = useValidateForm(formValidators);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const { sendContactUsMessage, error, isLoading } = useContactApi();
  const handleSubmit = useCallback(() => {
    setFormSubmitted(false);

    sendContactUsMessage({
      name,
      status,
      guests: showGuests ? parseInt(guests, 10) : undefined,
      message,
    }).subscribe(() => setFormSubmitted(true));
  }, [name, status, guests, showGuests, message, sendContactUsMessage]);

  return (
    <section className="container pt-5 pb-5 d-print-none">
      <h1 className="section-title">{t("Join our Party")}</h1>

      <Form onSubmit={handleSubmit} {...formValidation}>
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <FormGroup id="name" label={t("Your name")} {...nameValidation}>
              <Input
                id="name"
                value={name}
                setValue={setName}
                {...nameValidation}
              />
            </FormGroup>
          </div>

          <div className="col-md-6 col-lg-4">
            <FormGroup
              id="status"
              label={t("I'll be there")}
              {...statusValidation}
            >
              <Select
                id="status"
                options={statusOptions}
                value={status}
                setValue={setStatus}
                {...statusValidation}
              />
            </FormGroup>
          </div>

          {showGuests && (
            <div className="col-lg-4">
              <FormGroup
                id="guests"
                label={t("Number of guests")}
                {...guestsValidation}
              >
                <Input
                  id="guests"
                  value={guests}
                  setValue={setGuests}
                  type="number"
                  {...guestsValidation}
                />
              </FormGroup>
            </div>
          )}
        </div>

        <FormGroup id="message" label={t("Send us your wishes")}>
          <TextArea id="message" value={message} setValue={setMessage} />
        </FormGroup>

        <FormGroup>
          <ReCaptcha setChallengePassed={setCaptcha} />
          <CustomFieldError {...captchaValidation} />
        </FormGroup>

        <button
          type="submit"
          className="btn btn-primary"
          disabled={isLoading}
          aria-label={t("Send")}
        >
          {t("Send")}
        </button>

        {error && (
          <div className="alert alert-danger mt-3" role="alert">
            {error}
          </div>
        )}

        {formSubmitted && (
          <div className="alert alert-success mt-3" role="alert">
            {t("Thank you!")}
          </div>
        )}
      </Form>
    </section>
  );
};

export default Contact;
