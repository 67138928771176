import React, { useMemo } from "react";
import { isValidationErrorVisible } from "../../hooks/validation/validation";

interface ICustomFieldErrorProps {
  error: string;
  isTouched: boolean;
  isValid: boolean;
}

export const CustomFieldError = ({
  error,
  isTouched,
  isValid,
}: ICustomFieldErrorProps) => {
  const showError = useMemo(
    () => isValidationErrorVisible(isValid, isTouched),
    [isValid, isTouched],
  );

  return <>{showError && <div className="small text-danger">{error}</div>}</>;
};

export default CustomFieldError;
