import React, { FormEvent, ReactNode, useCallback } from "react";
import { noop } from "rxjs";

interface IFormProps {
  children: ReactNode;
  isValid?: boolean;
  onSubmit: () => void;
  touchFields?: () => void;
}

const Form = ({
  children,
  onSubmit,
  isValid = true,
  touchFields = noop,
}: IFormProps) => {
  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();

      touchFields();

      if (isValid) {
        onSubmit();
      }
    },
    [isValid, onSubmit, touchFields],
  );

  return <form onSubmit={handleSubmit}>{children}</form>;
};

export default Form;
