import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { TranslationMessage } from "../i18n";

function useApiRequest<T>() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<TranslationMessage>([
    "",
    {},
  ]);

  const sendRequest = useCallback((request: () => Observable<T>) => {
    setErrorMessage(["", {}]);
    setIsLoading(true);

    return request().pipe(
      tap(
        () => setIsLoading(false),
        (reason) => {
          if (
            Array.isArray(reason) &&
            reason.length > 0 &&
            typeof reason[0] === "string"
          ) {
            setErrorMessage([
              reason[0],
              typeof reason[1] === "object" ? reason[1] : {},
            ]);
          } else {
            setErrorMessage(["apiError", {}]);
          }

          setIsLoading(false);
        },
      ),
    );
  }, []);

  return { sendRequest, error: t(...errorMessage), isLoading };
}

export default useApiRequest;
