import React, { ChangeEvent, useCallback } from "react";
import { getValidationCssClass } from "../../hooks/validation/validation";

interface ITextAreaProps {
  id: string;
  value: string;
  setValue: (value: string) => void;
  isValid?: boolean;
  isTouched?: boolean;
}

const TextArea = ({
  id,
  value,
  setValue,
  isValid,
  isTouched,
}: ITextAreaProps) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);
    },
    [setValue],
  );

  return (
    <textarea
      id={id}
      rows={6}
      className={`form-control ${getValidationCssClass(isValid, isTouched)}`}
      value={value}
      onChange={handleChange}
    />
  );
};

export default TextArea;
