import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useWeddingDate from "../../hooks/useWeddingDate";
import configuration from "../../services/configuration";
import AnimateOnScroll from "../AnimateOnScroll";

interface IScheduleItemProps {
  title: string;
  imageUrl: string;
  coupleImageUrl: string;
  location: string;
  time: string;
  lat: number;
  lng: number;
}

const ScheduleItem = ({
  title,
  imageUrl,
  coupleImageUrl,
  location,
  time,
  lat,
  lng,
}: IScheduleItemProps) => {
  const { t } = useTranslation();
  const [showMap, setShowMap] = useState(false);
  const toggleMap = useCallback(() => {
    setShowMap((l) => !l);
  }, []);

  const { isLoaded: isGoogleMapsLoaded } = useJsApiLoader({
    id: configuration.id.googleMaps,
    googleMapsApiKey: configuration.googleMapsApiKey,
  });

  const { weddingDateString } = useWeddingDate();

  return (
    <AnimateOnScroll animation="fadeInLeft">
      <div className="row mb-5">
        <div className="col-md order-md-2 mb-3 mb-md-0">
          <div className="position-relative h-100">
            <h4 className="mb-3">{title}</h4>
            <p className="small text-muted mb-1">
              <span className="icon icon-location text-primary" /> {location}
            </p>
            <p className="small text-muted">
              <span className="icon icon-clock text-primary" />{" "}
              {weddingDateString}, {time}
            </p>

            {isGoogleMapsLoaded && (
              <button
                className="btn btn-link p-0"
                onClick={toggleMap}
                aria-label={showMap ? t("Hide map") : t("Show map")}
              >
                {showMap ? t("Hide map") : t("Show map")} &rsaquo;
              </button>
            )}

            <img src={coupleImageUrl} alt="" className="img-couple" />
          </div>
        </div>

        <div className="col-md col-lg-5 order-md-1 mb-3 mb-md-0">
          <div className="responsive-container rounded shadow">
            {isGoogleMapsLoaded && showMap ? (
              <GoogleMap
                zoom={14}
                center={{ lat, lng }}
                mapContainerClassName="responsive-item"
              >
                <Marker position={{ lat, lng }} />
              </GoogleMap>
            ) : (
              <img src={imageUrl} className="responsive-item" alt={title} />
            )}
          </div>
        </div>
      </div>
    </AnimateOnScroll>
  );
};

export default ScheduleItem;
