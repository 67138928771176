import { useCallback } from "react";
import { from } from "rxjs";
import { map } from "rxjs/operators";
import { dynamoDB } from "../../services/aws";
import useApiRequest from "../useApiRequest";

interface IContactUsRequest {
  name: string;
  status: string;
  guests?: number;
  message: string;
}

const useContactApi = () => {
  const { sendRequest, error, isLoading } = useApiRequest<{}>();

  const sendContactUsMessage = useCallback(
    ({ name, status, guests, message }: IContactUsRequest) =>
      sendRequest(() => {
        const now = new Date();
        const sortKey = `Contact-${now.toISOString()}`;

        return from(
          dynamoDB
            .putItem({
              TableName: "WeddingWebsite",
              Item: {
                partitionKey: { S: "ManosLinda" },
                sortKey: { S: sortKey },
                name: { S: name },
                status: { BOOL: status === true.toString() },
                ...(guests && { guests: { N: guests.toString() } }),
                ...(message && { message: { S: message } }),
                dateTime: { S: now.toISOString() },
              },
            })
            .promise(),
        ).pipe(map(() => ({})));
      }),
    [sendRequest],
  );

  return { sendContactUsMessage, error, isLoading };
};

export default useContactApi;
