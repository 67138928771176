import React, { ReactNode } from "react";

interface IFormGroupProps {
  id?: string;
  label?: string;
  children: ReactNode;
  error?: string;
}

const FormGroup = ({ id, label, children, error }: IFormGroupProps) => {
  return (
    <div className="form-group">
      {label && <label htmlFor={id}>{label}</label>}
      {children}

      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default FormGroup;
