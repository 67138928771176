import React, { createContext, ReactNode, useCallback, useState } from "react";
import { noop } from "rxjs";

interface IScriptLoaded {
  [key: string]: boolean;
}

export interface ILoadScriptContext {
  scripts: IScriptLoaded;
  updateScriptStatus: (script: string, status: boolean) => void;
}

export const LoadScriptContext = createContext<ILoadScriptContext>({
  scripts: {},
  updateScriptStatus: noop,
});

interface ILoadScriptProviderProps {
  children: ReactNode;
}

const LoadScriptProvider = ({ children }: ILoadScriptProviderProps) => {
  const [scripts, setScripts] = useState<IScriptLoaded>({});

  const updateScriptStatus = useCallback((script: string, status: boolean) => {
    setScripts((s) => ({ ...s, [script]: status }));
  }, []);

  return (
    <LoadScriptContext.Provider value={{ scripts, updateScriptStatus }}>
      {children}
    </LoadScriptContext.Provider>
  );
};

export default LoadScriptProvider;
