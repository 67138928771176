import React from "react";
import Contact from "../components/Contact";
import Countdown from "../components/Countdown";
import Invitation from "../components/Invitation";
import Schedule from "../components/Schedule/Schedule";
import Welcome from "../components/Welcome";

const Home = () => {
  return (
    <>
      <Welcome />
      <Invitation />
      <Schedule />
      <Countdown />
      <Contact />
    </>
  );
};

export default Home;
