import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import App from "../App";
import Home from "../pages/Home";
import LanguageSelector from "../pages/LanguageSelector";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />} errorElement={<LanguageSelector />}>
      <Route path="/" element={<LanguageSelector />} />
      <Route path="/el" element={<Home />} />
      <Route path="/en" element={<Home />} />
      <Route path="/de" element={<Home />} />
    </Route>,
  ),
);

export { router };
