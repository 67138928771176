import React, { ChangeEvent, useCallback } from "react";
import { getValidationCssClass } from "../../hooks/validation/validation";

export interface ISelectOption {
  label?: string;
  value?: string;
  disabled?: boolean;
}

interface ISelectProps {
  id: string;
  options: ISelectOption[];
  value: string;
  setValue: (value: string) => void;
  isValid?: boolean;
  isTouched?: boolean;
}

const Select = ({
  id,
  options,
  value,
  setValue,
  isValid,
  isTouched,
}: ISelectProps) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setValue(event.target.value);
    },
    [setValue],
  );

  return (
    <select
      id={id}
      className={`custom-select ${getValidationCssClass(isValid, isTouched)}`}
      value={value}
      onChange={handleChange}
    >
      {!value && <option disabled={true} />}
      {options.map((option) => (
        <option key={option.value} {...option} />
      ))}
    </select>
  );
};

export default Select;
