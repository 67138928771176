import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import Analytics from "./components/Analytics";
import IconsCredit from "./components/IconsCredit";
import LoadScript from "./components/LoadScript/LoadScript";
import LoadScriptProvider from "./components/LoadScript/LoadScriptProvider";
import configuration from "./services/configuration";

const App = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = `${t("Manos")} & ${t("Linda")}`;
  }, [t]);

  return (
    <LoadScriptProvider>
      <LoadScript
        id={configuration.id.googleTagManager}
        url={`https://www.googletagmanager.com/gtag/js?id=${configuration.googleTagManagerApiKey}`}
      >
        <LoadScript
          id={configuration.id.reCaptcha}
          url="https://www.google.com/recaptcha/api.js?render=explicit"
          onLoad="onload"
        >
          <Outlet />

          <IconsCredit />
        </LoadScript>
        <Analytics />
      </LoadScript>
    </LoadScriptProvider>
  );
};

export default App;
