import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import de from "./locales/de.json";
import el from "./locales/el.json";
import en from "./locales/en.json";

const languageDetector = new LanguageDetector(null, {
  order: ["path", "navigator"],
  caches: [],
});

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: { en, el, de },
    interpolation: {
      escapeValue: false,
    },
  });

export type TranslationMessage = [
  string,
  {
    [key: string]: any;
  },
];

export default i18n;
