import "./polyfills";

import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "./i18n";
import { router } from "./routing/routes";
import * as serviceWorker from "./serviceWorker";

import "animate.css/animate.compat.css";
import "./index.scss";
import "./styles/vendor/icomoon/style.css";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<RouterProvider router={router} />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
