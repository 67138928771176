import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationMessage } from "../../i18n";

export interface IValidationResult {
  isValid: boolean;
  error: TranslationMessage;
}

export type Validator = (value: string) => IValidationResult;

export interface IValidateField {
  error: string;
  touch: () => void;
  isTouched: boolean;
  isValid: boolean;
}

const useValidateField = (
  value: string,
  validators: Validator[],
): IValidateField => {
  const { t } = useTranslation();
  const [isTouched, setIsTouched] = useState<boolean | null>(null);
  const [validationResult, setValidationResult] = useState<IValidationResult>({
    isValid: true,
    error: ["", { value }],
  });

  const touch = useCallback(() => {
    setIsTouched(true);
  }, []);

  useEffect(() => {
    setIsTouched((to) => (to === null ? false : true));
  }, [value]);

  const validate = useCallback(() => {
    let validation: IValidationResult = {
      isValid: true,
      error: ["", { value }],
    };

    for (const validator of validators) {
      validation = validator(value);

      if (!validation.isValid) {
        break;
      }
    }

    setValidationResult(validation);
  }, [value, validators]);

  useEffect(() => {
    validate();
  }, [validate]);

  return {
    touch,
    isTouched: isTouched || false,
    isValid: validationResult.isValid,
    error: t(...validationResult.error),
  };
};

export default useValidateField;
