import React from "react";
import { useTranslation } from "react-i18next";
import churchImage from "../../images/church.jpg";
import partyCoupleImage from "../../images/couple-4.svg";
import churchCupleImage from "../../images/couple-6.svg";
import partyImage from "../../images/party.jpg";
import ScheduleItem from "./ScheduleItem";

const Schedule = () => {
  const { t } = useTranslation();

  return (
    <section className="container pt-5">
      <h1 className="section-title">{t("Our Wedding")}</h1>

      <ScheduleItem
        title={t("Church")}
        location={t("Agioi Apostoloi Church, Kato Daratso, Chania")}
        time={`7${t("pm")}`}
        lat={35.515647}
        lng={23.9776949}
        imageUrl={churchImage}
        coupleImageUrl={churchCupleImage}
      />
      <ScheduleItem
        title={t("Wedding Party")}
        location={t("Limnoupoli, Varipetro, Chania")}
        time={`8${t("pm")}`}
        lat={35.4686388}
        lng={23.9699811}
        imageUrl={partyImage}
        coupleImageUrl={partyCoupleImage}
      />
    </section>
  );
};

export default Schedule;
