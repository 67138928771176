import { IValidationResult } from "../useValidateField";

const isRequired = (errorMessage = "The field is required") => (
  value: string,
): IValidationResult => {
  const isValid = !!value;

  return {
    isValid,
    error: [
      isValid ? "" : errorMessage,
      {
        value,
      },
    ],
  };
};

export default isRequired;
