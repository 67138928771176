import React from "react";
import { useTranslation } from "react-i18next";

const IconsCredit = () => {
  const { t } = useTranslation();

  return (
    <div className="small p-1 text-center">
      {t("Icons made by")}{" "}
      <a
        href="https://www.flaticon.com/authors/freepik"
        title="Freepik"
        target="_blank"
        rel="noopener noreferrer"
      >
        Freepik
      </a>{" "}
      {t("from")}{" "}
      <a
        href="https://www.flaticon.com/"
        title="Flaticon"
        target="_blank"
        rel="noopener noreferrer"
      >
        www.flaticon.com
      </a>
    </div>
  );
};

export default IconsCredit;
