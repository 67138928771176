import { useCallback, useMemo } from "react";
import { IValidateField } from "./useValidateField";

const useValidateForm = (validators: IValidateField[]) => {
  const isValid = useMemo(
    () => validators.every((validator) => validator.isValid),
    [validators],
  );

  const touchFields = useCallback(() => {
    validators.forEach((validator) => validator.touch());
  }, [validators]);

  return {
    isValid,
    touchFields,
  };
};

export default useValidateForm;
